import { rep_base } from './rep_base'

class REP_ma_sold_agreements extends rep_base {
    // Notify: Report content not loaded at constructor step!
    __REPORT_ID;
    __REPORT_GROUP;
    __REPORT_NAME;

    constructor () {
        super();
    }

    attach () {
        this.__REPORT_ID    = 'REP_ma_sold_agreements';
        this.__REPORT_GROUP = 'B2C';
        this.__REPORT_NAME  = 'Продажи';

        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        let filter_controls = [];
        let element = {}

        element = {
            el: this.ReportContainer.querySelector('#rep_date_create'),
            ftype: 'datetime',
            fname:'create_date'
        }

        filter_controls.push(element);
        this.renderDateTimePicker(element, {defaultDate:[firstDay, lastDay]});

        element = {
            el: this.ReportContainer.querySelector('#rep_date_change'),
            ftype: 'datetime',
            fname:'change_date'
        }

        filter_controls.push(element);
        this.renderDateTimePicker(element, {defaultDate:[null, null]});

        element = {
            el: this.ReportContainer.querySelector('#companyList'),
            ftype: 'list',
            fname:'company_id',
        }

        filter_controls.push(element);

        this.renderDictionary(
            element,
            'company-list-item',
            '/get-companies-dictionary'
        );


        element = {
            el: this.ReportContainer.querySelector('#cityList'),
            ftype: 'list',
            fname:'city_id',
        }

        filter_controls.push(element);

        this.renderDictionary(
            element,
            'city-list-item',
            '/get-city-dictionary'
        );

        element = {
            el: this.ReportContainer.querySelector('#row-per-page'),
            ftype: 'list',
            fname: 'rows',
        }

        filter_controls.push(element);

        this.renderDictionary(
            element,
            'row-per-page-item',
            ['20', '50', '100']
        );

        element = {
            el: this.ReportContainer.querySelector('#productList'),
            ftype: 'list',
            fname:'product_id',
        }

        filter_controls.push(element);

        this.renderDictionary(
            element,
            'product-list-item',
            '/get-product-dictionary'
        );

        element = {
            el: this.ReportContainer.querySelector('#salechannellist'),
            ftype: 'list',
            fname:'sale_channel_id',
        }

        filter_controls.push(element);

        this.renderDictionary(
            element,
            'sale-channel-list-item',
            '/get-sale-channel-dictionary'
        );

        this.form_filter = filter_controls;

// setTimeout leave here. Need for wait until filter control creating.
        let tbl_data = null;
        setTimeout(() => {
            tbl_data = this.renderDataTable(
                this.ReportContainer.querySelector('#data_table'),
                {
                    name: this.__REPORT_NAME,
                    resource: '/get-sold-agreements',
                    cols: [
                        { title: 'Дата',           order: true,  fieldname: "for_date",         fieldmap: 0 },
                        { title: 'Город',          order: true,  fieldname: "city_id",          fieldmap: 1 },
                        { title: 'Продукт',        order: true,  fieldname: "product_id",       fieldmap: 2 },
                        { title: 'Канал',          order: false, fieldname: "sale_channel_id",  fieldmap: 3 },
                        { title: 'Статус',         order: false, fieldname: "sale_status_id",   fieldmap: 4 },
                        { title: 'Тип',            order: false, fieldname: "sale_type_id",     fieldmap: 5 },
                        { title: 'Дата изменения', order: true,  fieldname: "change_date",      fieldmap: 6 },
                        { title: 'Количество',     order: true,  fieldname: "cnt",              fieldmap: 7 },
                    ]
                },
            );

            this.ConfigureRecalculateModal(
                this.__REPORT_NAME +' ('+this.__REPORT_GROUP+')',
                this.__REPORT_ID,
                tbl_data
            )
        }, 1000)

        this.ReportContainer
            .querySelector('#rep_update')
            .addEventListener('click', () => {
                this.ReportContainer
                    .querySelector('#rep-table-pager-ma-sold-agreements')
                    .dataset.pagerIndex="1";

                tbl_data.reload();
            });

        this.ReportContainer
            .querySelector('#rep_export')
            .addEventListener('click', () => {
                tbl_data.export();
            });

        document.body.addEventListener('TableLoaded', (event) => {
            this.renderPager(
                this.ReportContainer
                    .querySelector('#rep-table-pager-ma-sold-agreements'),
                event.detail.pages,
                tbl_data
            );
        })
    }
}

export { REP_ma_sold_agreements }
