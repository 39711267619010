"use strict";

import { CommonHelpers } from './common';
import { DynamicLoader } from './report_dynaloader'


class ReportController extends CommonHelpers {
    __httpReq = new XMLHttpRequest();
    __ReportContainer;
    __ReportCollection = {
        index:              {url: "/reports/rep_index.html"},
        ma_charges:         {url: "/reports/rep_ma_charges.html"},
        ma_aa_b2c:          {url: "/reports/rep_ma_aa_b2c.html"},
        ma_sold_agreements: {url: "/reports/rep_ma_sold_agreements.html"},
        ma_aa_b2b:          {url: "/reports/rep_ma_aa_b2b.html"},
        ma_outflow_b2b:     {url: "/reports/rep_ma_outflow_b2b.html"},
        ma_b2b_sales:       {url: "/reports/rep_ma_b2b_sales.html"},
        ma_b2b_sales_qt:    {url: "/reports/rep_ma_b2b_sales_qt.html"},
        ma_outflow_b2b_qt:  {url: "/reports/rep_ma_outflow_b2b_qt.html"},
    };

    constructor () {
        super();
    }

    __content_loader (bind_element, resource, onDocumentReady = null) {
        this.__httpReq.onreadystatechange = function () {
            if (this.readyState === 4){
                let content_container = document.createElement('div');
                content_container.innerHTML = this.responseText;

                bind_element.innerHTML = '';

                content_container.childNodes.forEach(
                    (i) => bind_element.appendChild(i)
                );

                // document.body.dispatchEvent(new  Event(resource.event));

                if (onDocumentReady)
                    onDocumentReady();
            }
        }

        this.__httpReq.open('GET', resource);
        this.__httpReq.send();
    }

    showReport(report_name) {
        this.__content_loader(this.__ReportContainer, this.__ReportCollection[report_name].url, function () {
            let report_plugin = new DynamicLoader(report_name);

            if (report_plugin.__proto__.hasOwnProperty('attach'))
                report_plugin.__proto__.attach();
        });
    }

    attach () {
        this.__ReportContainer = document.getElementById('report-container');
    }
}

export {ReportController}
