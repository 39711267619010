"use strict";

import sha256 from 'crypto-js/sha256';
import { CommonHelpers } from './common';


class AuthController extends CommonHelpers {
    __ContentController;
    __LgnButton;
    __LgnInpUsername;
    __LgnInpPassword;
    __MnuUsername;
    __httpRequest = new XMLHttpRequest();

    constructor (ContentControllerInstance) {
        super();
        this.__ContentController = ContentControllerInstance;
        this.__MnuUsername = document.getElementById('main-menu-username');
    }

    __restAuthRequest() {
        let _this = this;

        this.__httpRequest.onreadystatechange = function() {
            if (this.readyState === 4){
                if (this.status === 200) {
                    let token = JSON.parse(this.responseText);
                    _this.systemNotice.hide();
                    if (token.hasOwnProperty('token')) {
                        _this.__MnuUsername.innerText = _this.__LgnInpUsername.value;
                        _this.setCookie(_this.auth_user_name,  _this.__LgnInpUsername.value);
                        _this.setCookie(_this.auth_token_name, token.token );
                        _this.__ContentController.dashboard_state();
                    }
                    //TODO: For Prod
                    // httpRequest.getResponseHeader('x-auth-token');
                } else {
                    _this.systemMessage('warning', 'Ошибка авторизации.')
                        .systemNotice
                        .show();
                }
            }
        };

        let auth_params = new FormData();
        auth_params.append('username', this.__LgnInpUsername.value);
        auth_params.append('password', sha256(this.__LgnInpPassword.value));

        this.__httpRequest.open('POST', this.baseUrl + '/login');
        this.__httpRequest.send(auth_params);
    }

    __restAuthTestRequest () {
        let _this = this;

        this.__httpRequest.onreadystatechange = function() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    let token = JSON.parse(this.responseText);
                    if (token.hasOwnProperty('token')) {
                        _this.setCookie(_this.auth_token_name, token.token );
                        _this.__MnuUsername.innerText = _this.getCookie(_this.auth_user_name);
                        _this.__ContentController.dashboard_state();
                    }
                } else {
                    _this.deleteCookie(_this.auth_token_name);
                    _this.__ContentController.login_state();
                }
            }
        };

        this.__httpRequest.open('GET', this.baseUrl + '/authtest');
//TODO: Replace to constructor part for test
        this.__httpRequest.setRequestHeader('Authorization', 'Bearer ' + this.getCookie(this.auth_token_name));
        this.__httpRequest.send();
    }

    __restLogoutRequest () {
        let _this = this;

        this.__httpRequest.onreadystatechange = function() {
            if (this.readyState === 4) {
                _this.deleteCookie(_this.auth_token_name);
                _this.__MnuUsername.innerText = '';
                _this.deleteCookie(_this.auth_user_name);
                _this.__ContentController.login_state();
            }
        };

        this.__httpRequest.open('POST', this.baseUrl + '/logout');
        this.__httpRequest.setRequestHeader('Authorization', 'Bearer ' + this.getCookie(this.auth_token_name));
        this.__httpRequest.send();
    }

    __validate () {
        let valid = true;
        if (! this.__LgnInpUsername.value){
            this.__LgnInpUsername.classList.add('is-invalid');
            valid = false;
        }
        if (! this.__LgnInpPassword.value){
            this.__LgnInpPassword.classList.add('is-invalid');
            valid = false;
        }

        return valid;
    }

    __try_login () {
        if (this.__validate()){
            this.systemMessage('progress', 'Ожидание авторизации...')
                .systemNotice
                .show();
            this.__restAuthRequest();
        } else {
            this.systemMessage('warning', 'Запоните все поля')
                .systemNotice
                .show();
        }
    }

    attach () {
        this.__LgnButton = document.getElementById('lgn_bnt');
        this.__LgnInpUsername = document.getElementById('lgn_username');
        this.__LgnInpPassword = document.getElementById('lgn_password');

        if (this.__LgnButton && this.__LgnInpUsername && this.__LgnInpPassword) {
            this.__LgnButton.addEventListener('click', () => {
                this.__try_login()
            });
            this.__LgnInpPassword.addEventListener('keydown', (e) => {
                if (e.key && e.key.toLowerCase() === 'enter')
                    this.__try_login()
            });
        }else
            throw 'Login form objects not fond'
    }

    authtest () {
        this.__restAuthTestRequest();
    }

    logout () {
        this.__restLogoutRequest();
    }
}

export { AuthController }
