import { REP_ma_aa_b2c }          from './reports/rep_ma_aa_b2c';
import { REP_ma_aa_b2b }          from './reports/rep_ma_aa_b2b';
import { REP_ma_sold_agreements } from './reports/rep_ma_sold_agreements'
import { REP_ma_charges }         from './reports/rep_ma_charges'
import { REP_ma_b2b_sales }       from './reports/rep_ma_b2b_sales'
import { REP_ma_b2b_sales_qt }    from './reports/rep_ma_b2b_sales_qt'
import { REP_ma_outflow_b2b_qt }  from './reports/rep_ma_outflow_b2b_qt'
import { REP_ma_outflow_b2b }     from './reports/rep_ma_outflow_b2b'
import { REP_index }     from './reports/rep_index'

class DynamicLoader {
    __ClassCollector = {
        index:REP_index,
        ma_aa_b2b: REP_ma_aa_b2b,
        ma_aa_b2c: REP_ma_aa_b2c,
        ma_sold_agreements: REP_ma_sold_agreements,
        ma_charges: REP_ma_charges,
        ma_b2b_sales: REP_ma_b2b_sales,
        ma_b2b_sales_qt: REP_ma_b2b_sales_qt,
        ma_outflow_b2b_qt: REP_ma_outflow_b2b_qt,
        ma_outflow_b2b: REP_ma_outflow_b2b,
    }

    constructor (className, opts) {
        return this.__ClassCollector[className] ? new this.__ClassCollector[className](opts) : null;
    }
}

export { DynamicLoader }
