"use strict";

import { Toast } from 'bootstrap'

class CommonHelpers {
    __system_notice = null;

    constructor () {
        this.__system_notice = new Toast(document.getElementById('system_notice'), {delay:1000});
    }

    get auth_token_name () {
        return 'auth-token';
    }

    get auth_user_name () {
        return 'login-user-name';
    }

    get baseUrl () {
        //Todo: unConmment for prod
        return window.location.origin+'/rest';
        // return 'http://sbill.loc:8010/rest';
    }

    get systemNotice () {
        return this.__system_notice;
    }

    systemMessage (type, message) {
        document.getElementById('system_notify_progress').style.display = 'none';
        document.getElementById('system_notify_info').style.display = 'none';
        document.getElementById('system_notify_warning').style.display = 'none';

        if (type == "progress")
            document.getElementById('system_notify_progress').style.display = 'block';
        if (type == "info")
            document.getElementById('system_notify_info').style.display = 'block';
        if (type == "warning")
            document.getElementById('system_notify_warning').style.display = 'block';

        document.getElementById('system_notify_message').innerText = message;

        return this
    }

    onSystemNoticeHide (func) {
        document.getElementById('system_notice')
            .addEventListener('hidden.bs.toast', function () {
            func();
        })
    }

    getCookie (name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    setCookie(name, value, options = {}) {
        options = {
            path: '/',
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    deleteCookie(name) {
        this.setCookie(name, "", {
            'max-age': -1
        })
    }

    elShow (elSelector) {
        document.querySelector(elSelector).style.setProperty('display', 'block ', 'important');
    }

    elHide (elSelector) {
        document.querySelector(elSelector).style.setProperty('display', 'none ', 'important');
    }
}

export { CommonHelpers }
