"use strict";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import  './scss/styles.scss'

import { ContentController }   from './content_controller'
import { AuthController }      from './auth_controller'
import { DashboardController } from './dashboard_controller'
import { ReportController }    from './report_controller'


let cc = new ContentController();
let ac = new AuthController(cc);
let dc = new DashboardController(ac);
let rc = new ReportController();

document.addEventListener('DOMContentLoaded', () => {
    ac.authtest();
})

document.body.addEventListener('LoginLoaded', () => {
    ac.attach();
})

document.body.addEventListener('LogoutEvent', () => {
    cc.login_state();
})

document.body.addEventListener('DashboardLoaded', () => {
    dc.attach();
    rc.attach();
    rc.showReport('index');

})

document.body.addEventListener('ReportReq', (event) => {
    rc.showReport(event.detail.report_name);
})
