"use strict";

import { CommonHelpers } from './common';


class ContentController extends CommonHelpers {
    __ContentCollection;
    __ContentHolder;

    constructor () {
        super();
        this.__ContentCollection = {
               login: { url: 'login.html',     menu: '#menu-login',     event:'LoginLoaded' },
           dashboard: { url: 'dashboard.html', menu: '#menu-dashboard', event:'DashboardLoaded' },
        };

        this.__ContentHolder = (document.getElementsByTagName('main'))[0];
    }

    __content_loader (bind_element, resource, onDocumentReady = null) {
        let httpReq = new XMLHttpRequest();

        httpReq.onreadystatechange = function () {
            if (this.readyState === 4){
                let content_container = document.createElement('div');
                content_container.innerHTML = this.responseText;

                // if (bind_element.firstElementChild)
                //     bind_element.removeChild(bind_element.firstElementChild);

                bind_element.innerHTML = '';

                content_container.childNodes.forEach(
                    (i) => bind_element.appendChild(i)
                );

                document.body.dispatchEvent(new  Event(resource.event));

                if (onDocumentReady)
                    onDocumentReady();
            }
        }

        httpReq.open('GET', resource.url);
        httpReq.send();

        Object.entries(this.__ContentCollection).forEach((k) => {
            k[1].url === resource.url ? this.elShow(k[1].menu) : this.elHide(k[1].menu)
        })
    }

    login_state (onDocumentReady = null) {
        this.__content_loader(
            this.__ContentHolder,
            this.__ContentCollection.login,
            onDocumentReady
        )
    }

    dashboard_state (onDocumentReady = null) {
        this.__content_loader(
            this.__ContentHolder,
            this.__ContentCollection.dashboard,
            onDocumentReady
        )
    }
}

export {ContentController}
