import { rep_base } from './rep_base'

class REP_index extends rep_base {
    // Notify: Report content not loaded at constructor step!
    __REPORT_ID;
    __REPORT_GROUP;
    __REPORT_NAME;

    constructor () {
        super();
    }

    attach () {
        this.__REPORT_ID = 'index';
        this.__REPORT_GROUP = '';
        this.__REPORT_NAME = 'Стартовая страница отчетов';
    }
}

export { REP_index }
