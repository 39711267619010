"use strict";

import { CommonHelpers } from './common';
import { Collapse } from 'bootstrap';

class DashboardController extends CommonHelpers {
    __AuthController;
    __LogoutButton;
    __MenuCollection = [
        {
            group_id: "report-menu-misc",
            group_name: "Общие",
            group_items: [
                {name:"Выручка", event: "ma_charges"}
            ]
        },
        {
            group_id: "report-menu-b2c",
            group_name: "B2C",
            group_items: [
                {name:"Активные абоненты", event: "ma_aa_b2c"},
                {name:"Продажи", event: "ma_sold_agreements"},
            ]
        },
        {
            group_id: "report-menu-b2b",
            group_name: "B2B",
            group_items: [
                {name:"Активные продукты", event: "ma_aa_b2b"},
                {name:"Продажи в РАП", event: "ma_b2b_sales"},
                {name:"Продажи (количество)", event: "ma_b2b_sales_qt"},
                {name:"Изменение выручки", event: "ma_outflow_b2b"},
                {name:"Отток \"Расторжение\"", event: "ma_outflow_b2b_qt"},
            ]
        },
    ];

    constructor (AuthControllerInstance) {
        super();

        this.__AuthController = AuthControllerInstance;
    }

    __menu_toggle(flg) {
        document
            .getElementById('report-menu-container-item')
            .querySelectorAll('.collapse').forEach( (e) => {
                let collapse = new Collapse(e, {toggle: false});
                flg ? collapse.hide() : collapse.show();
        });
    }

    __doMenuAction(event) {
        document.body.dispatchEvent(new  CustomEvent(
            'ReportReq',
            { detail: {  report_name: event } }
        ));
    }

    __menu_render() {
        Object.entries(this.__MenuCollection).forEach((k) => {
            let menu_group_template = document.querySelector('#report-menu-group').content.cloneNode(true);
            let label = menu_group_template.querySelector('label');
            label.innerText = k[1].group_name;
            label.dataset.bsTarget = '#' + k[1].group_id;
            menu_group_template.querySelector('div').id = k[1].group_id;

            Object.entries(k[1].group_items).forEach((i) => {
                let menu_item_template = document.querySelector('#report-menu-item').content.cloneNode(true);
                let a = menu_item_template.querySelector('a');
                a.innerText = i[1].name;
                a.addEventListener('click', () => this.__doMenuAction(i[1].event));
                menu_group_template.querySelector('ul').appendChild(menu_item_template);
            });

            document.querySelector('#report-menu-container-item').appendChild(menu_group_template);
        });

        document.querySelectorAll('.collapse').forEach((e) => {
            new Collapse(e, {toggle: false});
        });
    }

    attach () {
        this.__LogoutButton = document.getElementById('main-menu-logout');

        if (this.__LogoutButton)
            this.__LogoutButton.addEventListener('click', () => {this.__AuthController.logout()});

        this.__menu_render();

        document.getElementById('report-menu-rollup').addEventListener('click', () => {
            this.__menu_toggle(true);
        })
        document.getElementById('report-menu-expand').addEventListener('click', () => {
            this.__menu_toggle(false);
        })

    }
}

export { DashboardController }
