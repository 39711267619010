import { CommonHelpers } from '../common'

// https://flatpickr.js.org
import 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru';
import 'flatpickr/dist/flatpickr.min.css'
import { Modal } from 'bootstrap'


class rep_base {
    __FormFilterCollection;
    __TableInstance;

    constructor () {
        flatpickr.localize(Russian);
        flatpickr.setDefaults({
            shorthandCurrentMonth: true,
            monthSelectorType:'static',
            dateFormat: "d.m.Y",
            mode: "range",
            // enableTime: true,
            // time_24hr: true,
            onChange: (odt,sdt, i ) => {
                document.querySelector('.main-container').setAttribute('data-'+i.element.id, sdt)
            },
        });

        this.__FormFilterCollection = null;
        this.__TableInstance = null;
    }

    __dataContentLoader (method, resource, params, onDocumentReady = null) {
        let httpReq = new XMLHttpRequest();
        let CommonHelpersInstance = new CommonHelpers();

        httpReq.onreadystatechange = function () {
            if (this.readyState === 4) {

                CommonHelpersInstance.systemNotice.hide();
                let content = null;
                try {
                    content = JSON.parse(this.responseText);
                } catch(e) {
                    console.log(e)
                } finally {
                    if (null !== content && content.hasOwnProperty('token')) {
                        CommonHelpersInstance.setCookie(CommonHelpersInstance.auth_token_name, content.token);
                        if (onDocumentReady) {
                            let pages = content.hasOwnProperty('pages') ? content.pages : null;
                            onDocumentReady(content.data, pages);
                        }
                    } else {
                        CommonHelpersInstance.deleteCookie(CommonHelpersInstance.auth_token_name);
                        document.body.dispatchEvent(new Event('LogoutEvent'));
                    }
                }
            }
        }

        // let req_post_params = new FormData();
        let req_get_params  = '?';

        Object.entries(params).forEach((k) => {
            // req_post_params.append(k[0], k[1]);
            req_get_params += k[0]+'='+k[1]+'&';
        });

        req_get_params  = method === 'GET' ? req_get_params.slice(0, -1) : '';

        httpReq.open(method, CommonHelpersInstance.baseUrl+resource+req_get_params);
//TODO: Replace to constructor part for test
        httpReq.setRequestHeader('Authorization', 'Bearer ' + CommonHelpersInstance.getCookie(CommonHelpersInstance.auth_token_name));
        CommonHelpersInstance.systemMessage('progress', 'Загрузка данных').systemNotice.show();
        httpReq.send(JSON.stringify(params));
    }

    dataContentLoaderGET (resource, params = null, onDocumentReady = null) {
        if ( resource )
            this.__dataContentLoader('GET', resource, params, onDocumentReady);
    }

    dataContentLoaderPOST (resource, params = null, onDocumentReady = null) {
        if ( resource )
            this.__dataContentLoader('POST', resource, params, onDocumentReady);
    }

    renderDictionary(element, template, resource) {
        let _this = this;

        element.el.dataset.ftype = element.ftype;
        element.el.dataset.fname = element.fname;

        element.el.addEventListener('click', (e) => {
            e.stopPropagation();
        })

        if (typeof(resource) === 'string') {
            this.dataContentLoaderGET(resource, {},
                function (content) {
                    let list = JSON.parse(content);
                    if (list.length) {
                        list.forEach((i, k) => {
                            let item_template = _this.ReportContainer.querySelector('#' + template).content.cloneNode(true);
                            let input = item_template.querySelector('input');
                            let label = item_template.querySelector('label');

                            input.id = template + '-' + k;
                            input.value = i.key;
                            if (i.hasOwnProperty('default') && i.default == 'Y')
                                input.checked = true;
                            label.setAttribute('for', input.id);
                            label.innerText = i.name;

                            element.el.appendChild(item_template);
                        });
                    } else {
                        _this.ReportContainer
                            .querySelector('#'+element.el.getAttribute("aria-labelledby"))
                            .setAttribute('disabled', true);
                    }
                })
        }

        if (typeof(resource) === 'object') {
            resource.forEach((i, k) => {
                let item_template = this.ReportContainer.querySelector('#' + template).content.cloneNode(true);
                let input = item_template.querySelector('input');
                let label = item_template.querySelector('label');

                input.id = template + '-' + k;
                input.value = i;
                if (!k)
                    input.checked = true;
                label.setAttribute('for', input.id);
                label.innerText = i;


                element.el.appendChild(item_template);
            })
        }
    }

    renderDateTimePicker (element, opts = null) {
        if (opts)
            flatpickr.setDefaults(opts);

        let saved_data = document.
                        querySelector('.main-container').
                        getAttribute('data-'+element.el.id);

        if (saved_data) {
            let getDate = (d) => {
                let arr_d = d.split('.');
                return new Date(arr_d[2], parseInt(arr_d[1])-1, arr_d[0])
            };

            let dates = saved_data.split('—');

            let firstDay = dates.length>0 ? getDate(dates[0]) : null;
            let lastDay  = dates.length>1 ? getDate(dates[1]) : null;

            flatpickr.setDefaults({defaultDate:[firstDay, lastDay]})
        }

        element.el.dataset.ftype = element.ftype;
        element.el.dataset.fname = element.fname;

        flatpickr(element.el);
    }

    get form_filter () {
        let filter = {};

        this.ReportContainer.querySelectorAll('.order').forEach((el) => {
            if (el.classList.contains('bi-chevron-up')) {
                filter['order_by'] = el.dataset.order;
                filter['order'] = 'desc';
            }
            if (el.classList.contains('bi-chevron-down')) {
                filter['order_by'] = el.dataset.order;
                filter['order'] = 'asc';
            }
        });

        let pager = this.ReportContainer.querySelector('.pagination').dataset.pagerIndex;
        if (pager)
            filter['page'] = pager;

        if ( null !== this.__FormFilterCollection && 'object' === typeof (this.__FormFilterCollection) ) {
            Object.entries(this.__FormFilterCollection).forEach((i) => {
                if (i[1].ftype === 'list') {
                    let selected = [];

                    i[1].el.querySelectorAll('input[type="checkbox"]:checked').forEach((inp) => {
                        selected.push(inp.value);
                    });

                    if ( selected.length ) {
                        filter[i[1].fname] = selected;
                        selected = [];
                    }

                    i[1].el.querySelectorAll('input[type="radio"]:checked').forEach((inp) => {
                        selected.push(inp.value);
                    });

                    if ( selected.length )
                        filter[i[1].fname] = selected.join(',');
                }

                if (i[1].ftype === 'datetime' && '' !== i[1].el.value) {
                    let dates = i[1].el.value.split(' ');
                    if (dates.length === 3) {
                        let dt_parts = dates[0].split('.');
                        filter[i[1].fname+'_begin'] = dt_parts[2]+'-'+dt_parts[1]+'-'+dt_parts[0]+' 00:00:00';
                        dt_parts = dates[2].split('.');
                        filter[i[1].fname+'_end'] = dt_parts[2]+'-'+dt_parts[1]+'-'+dt_parts[0]+' 23:59:59';
                    } else {
                        let dt_parts = dates[0].split('.');
                        filter[i[1].fname+'_begin'] = dt_parts[2]+'-'+dt_parts[1]+'-'+dt_parts[0]+' 00:00:00';
                        dt_parts = dates[0].split('.');
                        filter[i[1].fname+'_end'] = dt_parts[2]+'-'+dt_parts[1]+'-'+dt_parts[0]+' 23:59:59';
                    }
                }
            })
        }

        return filter;
    }

    set form_filter (filter_list) {
        this.__FormFilterCollection = filter_list;
    }

    __loadDataForExport (el, resource, cols, file_name) {
        let filter = this.form_filter;

        if (filter.hasOwnProperty('page'))
            filter['page'] = 1;

        if (filter.hasOwnProperty('rows'))
            filter['rows'] = 10000000;

        this.dataContentLoaderPOST(resource, filter, function (content, pages) {
            let data_items = JSON.parse(content);
            let csv_raw = 'data:text/csv;charset=utf-8,';

            Object.entries(data_items).forEach((i) => {
                Object.entries(cols).forEach((c) => {
                    csv_raw += i[1][c[1].fieldmap]+';';
                })
                csv_raw += '\n';
            });

            let dt = new Date().toLocaleString();
            dt = dt.replaceAll('.', '_');
            dt = dt.replaceAll(' ', '');
            dt = dt.replaceAll(',', '_');
            dt = dt.replaceAll(':', '_');
            let e_uri = encodeURI(csv_raw);
            let download_link = document.createElement('a');
            download_link.setAttribute('href', e_uri);
            download_link.setAttribute('download', file_name+'_'+dt+'.csv');
            document.body.appendChild(download_link);
            download_link.click();
        });
    }

    __loadDataTable (el, resource, cols) {
        let dataContainer = el.getElementsByTagName('tbody')[0];
        let _this = this;
        dataContainer.innerHTML = '';

        this.dataContentLoaderPOST(resource, this.form_filter, function (content, pages) {
            let table_rows = JSON.parse(content);

            if (table_rows.length) {
                Object.entries(table_rows).forEach((i) => {
                    let trb = document.createElement('tr');
                    Object.entries(cols).forEach((c) => {
                        let tdb = document.createElement('td');
                        tdb.innerText = i[1][c[1].fieldmap];
                        trb.appendChild(tdb);
                    })
                    dataContainer.appendChild(trb);
                });
            } else {
                let trb = document.createElement('tr');
                let tdb = document.createElement('td');
                tdb.setAttribute('colspan', '100%');
                tdb.classList.add('table-empty-data');
                tdb.innerText = 'Данные отсутствуют';
                trb.appendChild(tdb);
                dataContainer.appendChild(trb);
            }

            document.body.dispatchEvent(new CustomEvent('TableLoaded', { detail: {  pages: pages } }));
        });
    }

    renderPager(el, pages, tbl_instance) {
        if (!el)
            return;
        el.innerHTML = '';
        el.dataset.pagerCount = pages;
        // el.dataset.pagerIndex = String(1);
        el.dataset.pagerType  = '';
        this.__TableInstance = tbl_instance;
        let _this = this;

        if (! el.dataset.hasOwnProperty('listener')) {
            el.dataset.listener = '1';
            el.addEventListener('click', (ev) => {
                if (ev.target.dataset.hasOwnProperty('pagerIndex')) {
                    let t_pager = el.dataset.pagerType;
                    let c_index = parseInt(el.dataset.pagerIndex);
                    let n_index = parseInt(ev.target.dataset.pagerIndex);
                    let m_index = parseInt(el.dataset.pagerCount);

                    if (m_index) {
                        if (n_index === -1)
                            n_index = c_index > 1 ? c_index - 1 : 1;

                        if (n_index === 0)
                            n_index = c_index + 1 > m_index ? m_index : c_index + 1;

                        if (c_index !== n_index) {
                            if (t_pager === 'large')
                                el.querySelector('input').value = String(n_index);

                            el.dataset.pagerIndex = String(n_index);
                            this.__TableInstance.reload();
                        }
                    }
                } else if ([ev.target.id, ev.target.parentElement.id].includes('tbl-pager-label')) {
                    let page = parseInt(el.querySelector('input').value);
                    if (page && page > 0 && page <= el.dataset.pagerCount){
                        el.dataset.pagerIndex = String(page);
                        this.__TableInstance.reload();
                    }

                }
            });
        }

        el.innerHTML = '<li class="page-item"><a class="page-link" data-pager-index="-1" href="#"><i data-pager-index="-1" class="bi-chevron-left"></i></a></li>';
        if ( pages < 10 ) {
            el.dataset.pagerType = 'small';
            for (let i = 1; i<=pages; i++) {
                let class_active = parseInt(el.dataset.pagerIndex) === i ? 'page-link-active' : '';
                el.innerHTML = el.innerHTML + '<li class="page-item"><a data-pager-index="'+ i +'" class="page-link '+class_active+'" href="#">'+ i +'</a></li>';
            }
        } else {
            el.dataset.pagerType = 'large';
            el.innerHTML = el.innerHTML + `
                <li class="page-item input-group pe-2 ps-2 tbl-pager">
                    <input type="text" class="form-control" placeholder="Стр." value="${el.dataset.pagerIndex}" aria-describedby="tbl-pager-label">
                    <span class="input-group-text rounded-end" id="tbl-pager-label"><i class="bi-file-earmark-text"></i></span>
                    <label class="ms-2">из ${pages}</label>
                </li> 
            `;
        }
        el.innerHTML = el.innerHTML + '<li class="page-item"><a class="page-link" data-pager-index="0" href="#"><i data-pager-index="0" class="bi-chevron-right"></i></a></li>';
    }

    renderDataTable (el, opts) {
        let thead = document.createElement('thead');
        let trh   = document.createElement('tr');

        Object.entries(opts.cols).forEach((i) => {
            let th = document.createElement('th');
            th.innerHTML = i[1].title + (i[1].order ? ' <i class="bi-chevron-expand order order-default" data-order="'+ i[1].fieldname +'">' : '');
            trh.appendChild(th);
        });
        thead.classList.add('user-select-none');
        thead.appendChild(trh);

        el.appendChild(thead);

        let allOrders = el.querySelectorAll('.order');

        allOrders.forEach((e) => {
            e.addEventListener('click', (ev) => {
                e.classList.remove('order-default');
                e.classList.remove('bi-chevron-expand');

                if (e.classList.contains('bi-chevron-up')){
                    e.classList.remove('bi-chevron-up');
                    e.classList.add('bi-chevron-down');
                } else {
                    e.classList.remove('bi-chevron-down');
                    e.classList.add('bi-chevron-up');
                };

                allOrders.forEach((i) => {
                    if (! e.classList.contains('bi-chevron-expand') && i.dataset.order !== e.dataset.order) {
                        i.classList.add('order-default');
                        i.classList.remove('bi-chevron-up');
                        i.classList.remove('bi-chevron-down');
                        i.classList.add('bi-chevron-expand');
                    }
                })

                this.__loadDataTable(el, opts.resource, opts.cols);
            })
        });

        let tbody = document.createElement('tbody');
        el.appendChild(tbody);

        this.__loadDataTable(el, opts.resource, opts.cols);

        let renderedTable = new Object();

        renderedTable.base = this;
        renderedTable.el = el;
        renderedTable.resource = opts.resource;
        renderedTable.cols = opts.cols;
        renderedTable.reload = function () {
            this.base.__loadDataTable(this.el, this.resource, this.cols);
        }
        renderedTable.export = function () {
            this.base.__loadDataForExport(this.el, this.resource, this.cols, opts.name);
        }

        return renderedTable;
    }

    get ReportContainer () {
        return document.getElementById('report-container');
    }

    ConfigureRecalculateModal (headLabel, reportId, data_container) {
        let partModal = document.getElementById('recalculateModal');
        let label = partModal.querySelector('#recalculateModalLabel');
        label.innerText = headLabel;
        label.dataset.reportid = reportId;

        let recalcModal = new Modal(partModal);

        let calendar = partModal.querySelector('#recalculate-date');
        if (! calendar.classList.contains('flatpickr-input'))
            flatpickr(partModal.querySelector('#recalculate-date'), { mode: 'single' });

        partModal
            .querySelector('#recalculate-button')
            .addEventListener('click', () => {
                if (calendar.value) {
                    this.dataContentLoaderPOST('/rep-recalculate',
                        {
                            report:reportId,
                            rdate:calendar.value
                        }, function () {
                            data_container.reload();
                        });
                    recalcModal.hide();
                } else {
                    calendar.classList.add('is-invalid');
                }
            });
    }
}

export { rep_base }
